<template>
  <div>
    <el-button class="btnAdd" size="small" type="primary" @click="dialogVisible = true;">添加菜单</el-button>
    <el-button class="btnAdd" size="small" type="primary" @click="dialogVisible2 = true;">添加子菜单</el-button>

    <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%;margin-bottom: 20px;"
    :data="tableData" row-key="id" border lazy :load="load" :key="key"
    :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    :height="tableHeight" >
    <el-table-column prop="name" label="菜单编码"></el-table-column>
      <el-table-column prop="text" label="菜单名称"></el-table-column>
      <el-table-column prop="manage" label="管理">
        <template slot-scope="scope">
            <el-button size="mini" type="primary" class="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" class="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(totalCount)">
    </el-pagination>


    <el-dialog title="添加菜单" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" label-width="100px">
        <el-form-item label="菜单编码:">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单名称:">
          <el-input v-model="form.text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false;addMenu()">添 加</el-button>
        <el-button @click="dialogVisible = false;cancel()">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="添加子菜单" :visible.sync="dialogVisible2" width="30%">
      <el-form :model="form2" label-width="100px">
        <el-form-item label="菜单编码:">
          <el-input v-model="form2.name"></el-input>
        </el-form-item>
        <el-form-item label="菜单名称:">
          <el-input v-model="form2.text"></el-input>
        </el-form-item>
        <el-form-item label="父菜单:">
          <el-select v-model="form2.parentId" filterable placeholder="请选择父菜单">
            <el-option
              v-for="item in parentMenu"
              :key="item.id" :label="item.text" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible2 = false;addSubMenu()">提 交</el-button>
        <el-button @click="dialogVisible2 = false;cancel()">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="form3.title" :visible.sync="dialogVisible3" width="30%">
      <el-form :model="form" label-width="100px">
        <el-form-item :label="form3.label1">
          <el-input v-model="form3.name"></el-input>
        </el-form-item>
        <el-form-item :label="form3.label2">
          <el-input v-model="form3.text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible3 = false;updateMenu()">提 交</el-button>
        <el-button @click="dialogVisible3 = false;cancel()">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {sanyouAxios} from "@/api";

export default {
  name: "menuManage",
  data() {
    return {
      currentUserId:"",
      //表格高度
      tableHeight:500,
      //表格数据
      tableData:[],
      key:0,
      //分页
      currentPage:1,
      pageSize: 10,
      totalCount:0,
      //查询条件
      keywords:'',

      //添加菜单
      dialogVisible:false,
      form:{
        name:"",
        text:""
      },
      //添加子菜单
      dialogVisible2:false,
      form2:{
        name:"",
        text:"",
        parentId:""
      },
      parentMenu:[],
      //编辑
      dialogVisible3:false,
      form3:{
        id:"",
        name:"",
        text:"",
        title:"",
        label1:"",
        label2:"",
      },
    };
  },
  methods: {
    //添加菜单
    addMenu(){
        let _this = this;
        sanyouAxios({
          headers:{
            userId:this.currentUserId
          },
          method: 'POST',
          url: '/menu/addMenu',
          data: {
            name:_this.form.name,
            text:_this.form.text
          }
      })
      .then(res => {
        if(res.data.status == 200){
          this.$message({
              duration: 2000,
              message: '添加成功!',
              type: 'success'
          });
        }else{
          this.$message({
            type: 'warning',
            duration: 2000,
            message: res.data.msg
          });
        }
        let pageSize = this.pageSize;
        let currentPage = this.currentPage;
        let query = this.keywords;
        this.loadData(query,currentPage,pageSize);
        this.cancel();
      });
    },
    //取消
    cancel(){
      this.form.name="";
      this.form.text="";

      this.form2.name="";
      this.form2.text="";
      this.form2.parentId="";

      this.form3 = {
        id:"",
        name:"",
        text:"",
        title:"",
        label1:"",
        label2:"",
      };
    },
    //每页显示数据量变更
    handleSizeChange: function (val) {
        this.pageSize = val;
        let currentPage = this.currentPage;
        let query = this.keywords;
        if(query == null || query == NaN)
            query = "";
        this.loadData(query,currentPage,val);
    },
    //页码变更
    handleCurrentChange: function (val) {
        this.currentPage = val;
        let pageSize = this.pageSize;
        this.loadData("",val,pageSize);
    },
    //加载菜单数据
    loadData: function(query, page, pageSize){
      let _this = this;

      if(query == null || query == NaN)
          query = "";
      if(page == null || page == NaN)
          page = 1;
      if(pageSize == null || pageSize == NaN)
          pageSize = 10;

        sanyouAxios({
        headers:{
          userId:this.currentUserId
        },
        method: 'GET',
        url:'/menu/query?query='+ query +'&page=' + page + '&pageSize=' + pageSize
      })
      .then(res => {
          if(res.data.status == 200){
            let data = res.data.data;
            _this.tableData = data.rows;
            _this.totalCount = data.records;
          }else{
            this.$message({
              type: 'warning',
              duration: 2000,
              message: '没有权限查看!'
            });
          }
      });

        sanyouAxios({method: 'GET',
            url:'/menu/getParentMenu'
        })
      .then(res => {
        _this.parentMenu = res.data.data
      })
    },
    //加载子菜单
    load(tree, treeNode, resolve) {
      let parentId = tree.id;
      let subList = [];

        sanyouAxios({
        headers:{
          userId:this.currentUserId
        },
        method: 'GET',
        url:'/menu/querySubMenu?parentId=' + parentId
      })
      .then(res => {
        if(res.data.status == 200){
          subList = res.data.data;
        }else{
          this.$message({
            type: 'warning',
            duration: 2000,
            message: '没有权限查看!'
          });
        }
      });

      setTimeout(() => {
        resolve(subList);
      }, 500)
    },
    //添加下级
    handleAdd(index,row){
      this.dialogVisible2 = true;
      this.form2.parentId = row.id;
    },
    addSubMenu(){
        let _this = this;
        sanyouAxios({
          headers:{
            userId:this.currentUserId
          },
          method: 'POST',
          url: '/menu/addSubMenu',
          data: {
            name: _this.form2.name,
            text: _this.form2.text,
            parentId:_this.form2.parentId
          }
      })
      .then(res => {
        if(res.data.status == 200){
          _this.$message({
            duration: 2000,
            message: '添加成功!',
            type: 'success'
          });
        }else{
          _this.$message({
            type: 'warning',
            duration: 2000,
            message: res.data.msg
          });
        }

        let pageSize = this.pageSize;
        let currentPage = this.currentPage;
        let query = this.keywords;
        this.loadData(query,currentPage,pageSize);
        this.cancel();
        this.key++;
      });
    },
    //编辑
    handleEdit(index,row){
      this.dialogVisible3 = true;
      this.form3.id = row.id;
      let parent = row.parent;
      if(parent == 1){
        this.form3.title = "菜单编辑";
        this.form3.label1 = "菜单编码";
        this.form3.label2 = "菜单名称";
      }else if(parent == 0){
        this.form3.title = "子菜单编辑";
        this.form3.label1 = "子菜单编码";
        this.form3.label2 = "子菜单名称";
      }
      this.form3.name = row.name;
      this.form3.text = row.text;
    },
    updateMenu(){
        sanyouAxios({
        headers:{
          userId:this.currentUserId
        },
        method: 'POST',
        url: '/menu/updateMenu',
        data: {
          id:this.form3.id,
          name: this.form3.name,
          text:this.form3.text
        }
      }).then(res => {
        //console.log(res);
        if(res.data.status == 200){
          this.$message({
            duration: 2000,
            message: '修改成功!',
            type: 'success'
          });

        }else{
          this.$message({
            type: 'warning',
            duration: 2000,
            message: res.data.msg
          });
        }
        let pageSize = this.pageSize;
        let currentPage = this.currentPage;
        let query = this.keywords;
        this.loadData(query,currentPage,pageSize);
        this.cancel();
        this.key++;
      });

    },
    //删除
    handleDelete(index,row){
      //console.log(row)
      this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
          sanyouAxios({
            headers:{
              userId:this.currentUserId
            },
            method: 'POST',
            url: '/menu/deleteMenu',
            data: {
                id: row.id,
                parent: row.parent
            }
          })
          .then(res => {
            if(res.data.status == 200){
              this.$message({
                  type: 'success',
                  duration: 2000,
                  message: '删除成功!'
              });

            }else{
              this.$message({
                  duration: 2000,
                  message: res.data.msg,
                  type: 'warning'
              });
            }
            let currentPage = this.currentPage;
            let pageSize = this.pageSize;
            let query = this.keywords;
            this.loadData(query,currentPage,pageSize);
            this.cancel();
            this.key++;
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 2000,
          message: '已取消删除'
        });
      });
    },
    //搜索
    doSearch(){
      let query = this.keywords;
      let currentPage = this.currentPage;
      let pageSize = this.pageSize;
      this.loadData(query,currentPage,pageSize);
    }

  },
  mounted(){
    this.$nextTick(() => {
        this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 200;
        window.onresize = () => {
            this.tableHeight = window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 200;
        };
    });
  },
  created() {
    this.currentUserId = localStorage.getItem('id');

    let currentPage = this.currentPage;
    let pageSize = this.pageSize;
    this.loadData("",currentPage,pageSize);
  }
};
</script>

<style scoped>

  .btnAdd {
    margin: 10px 10px;
    float: left;
  }
  .btnSearch {
    margin: 10px 10px;
    float: right;
  }
  .inputSearch {
    float: right;
    width: 250px;
    padding-right: 10px;
    padding-top: 5px;
  }
</style>
